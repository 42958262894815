@keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes slide-up {
  0%{
    transform: translateY(50px);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes fade-in-and-slide-up {
  0%{
    opacity: 0;
    transform: translateY(50px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}


