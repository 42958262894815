.feature-copy{
  align-items: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Linotte Semibold" Arial;
  justify-content: center;
  text-align: left;

  h2{
    color: $dark-slate;
    font-size: 32px;
    margin: 0;

  }

  p{
    color: $slate;
    font-size: 16px;
    margin: 0;
    margin-top: 8px;
  }

}