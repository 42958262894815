.feature-creative{
  align-self: center;
  display: none;
  flex: 1;
  :nth-of-type(even) > & { margin-left: 80px; }
  :nth-of-type(odd) > & { margin-right: 32px; }
}

#feature-1-creative{
  margin-bottom: -50px;
  margin-top: -50px;
  margin-left: -77px;
}

#feature-2-creative{
  margin-bottom: -100px;
}