* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
html {
  font-size: 62.5%;
  background-color: #313547;
}
body {
  animation: fade-in 1s;
  align-items: stretch;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: 'Linotte Bold', Helvetica, Arial;
  justify-content: center;
  transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
}
a, a:visited {
  color: #fff;
  text-decoration: none;
}