.brands {
  background-color: $pale-slate;
  height: 184px;
  overflow: hidden;
  width: 100%;

  
  .slider{
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 204px;
    justify-content: left;
    width: 100%;
    overflow: scroll;

    div {
      background-color: #fbfcfd;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%;
      box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
      flex-shrink: 0;
      border-radius: 5px;
      height: 63px;
      margin: 0 12px;
      margin-top: -20px;
      width: 140px;

      &:nth-child(1){
        background-image: url("../img/brand-fossil.svg");
      }

      &:nth-child(2){
        background-image: url("../img/brand-alex-and-ani.svg");
      }

      &:nth-child(3){
        background-image: url("../img/brand-levis.svg");
      }

      &:nth-child(4){
        background-image: url("../img/brand-guess.svg");
        background-size: 32%;
      }

      &:nth-child(5){
        background-image: url("../img/brand-stuart-weitzman.svg");
      }

      &:nth-child(6){
        background-image: url("../img/brand-lark-and-ro.svg");
      }

      &:nth-child(7){
        background-image: url("../img/brand-theory.svg");
      }

      &:nth-child(8){
        background-image: url("../img/brand-calvin.svg");
      }
      

    }
  }
  
}