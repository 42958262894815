
a.btn-primary {
  $r: 8px; 

  background-color: #fff;
  border-radius: $r;
  box-shadow: 7px 7px 13px rgba(0,0,0,0.13);
  color: $base;
  display: inline-block;
  font-size: 22px;
  padding: 10px 25px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out;


  &:visited{
    color: $base;
  }

  // Box shadow animation
  // http://tobiasahlin.com/blog/how-to-animate-box-shadow/
  &:after{
    border-radius: $r;
    box-shadow: 7px 7px 15px rgba(0,0,0,0.15);
    content: "";
    left: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  &:hover{
    transform: scale(1.025, 1.025);
    
    &:after {
      opacity: 1;
    }

  }

  &:active{
    transform: scale(1, 1);
  }
}

