@media only screen and (min-width:320px) {

}
@media only screen and (min-width:480px) {

}
@media only screen and (min-width:768px) {
  h1{
    font-size: 64px;
  }
  h2{
    font-size: 24px;
  }
  .main h2 { display: inline-block; }
  .header .logo {
    img { height: 40px; }
    p { font-size: 24px; }
  }
  .footer h2 span { display: inline }
  .feature-creative { display: inline-block; }
  .row-row-row-your-boat { padding: 80px 110px; }
  .brands .slider div { margin: 0 24px; }
}
@media only screen and (min-width:1024px) {

}
@media only screen and (min-width:1140px) {

}
@media only screen and (min-width:1280px) {

}
@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
