.jumbotron { 
  align-items: center;
  background: linear-gradient(to bottom right, $lighten, $darken);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0 32px;

  .btn-primary { margin-top: 32px }

  > div, a {
    animation: fade-in-and-slide-up 1s; 
  }

  h1{
    margin-bottom: 0px;
  }
  h2 {
    display: none;
    span { display: inline-block; }
    margin-top: 24px;
  }

  img{
    bottom: 32px;
    left: 50%;
    margin-left: -12.5px;
    position: absolute;
    width: 25px;
  }
}