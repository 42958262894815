.footer {
  align-content: center;
  background: linear-gradient(to bottom right, $lighten, $darken);
  display: flex;
  flex-direction: column;
  height: 420px;
  justify-content: center;
  padding: 32px;
  text-align: center;
  width: 100%;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  h2 {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 50px;
    span{
      display: none;
    }
  }

  ul {
    font-size: 18px;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 10px;

      a{
        opacity: 0.5;
        transition: opacity 0.4s;
        &:hover { opacity: 1 }
      }
    }    
  }

}
