@font-face {
  font-family: 'Linotte Semibold';
  src: url('../fonts/linotte-semibold.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Linotte Bold';
  src: url('../fonts/linotte-bold.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

h1{
  font-size: 48px;
}
h2{
}

