.header{
  padding: 32px;
  position: absolute;
  top: 0;
  width: 100%;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;

    p{
      display: inline-block;
      font-size: 16px;
      margin: 0;
    }

    img{
      display: inline-block;
      height: 25px;
      margin-right: 8px;
    }
  }
}

